@use "../mixins/mq";

.c-more_info--grid.c-more_info--grid {

    margin-bottom: 1rem;
    grid-gap: 6rem 0;


    @include mq.mq($from: medium){
        grid-gap: 5rem;
        grid-column-gap: 5rem;
    }
    
    @include mq.mq($from: large){
        grid-gap: 4rem;
        grid-column-gap: 4rem;
    }
}