@use "../_config/colors";
@use "../mixins/background-svg";
@use "../mixins/mq";
@use "svg";

$sizes: h1, h2, h3, h4, h5, h6, lead;
$sizesbold: h1, h3, h3;

@each $size in $sizes {
  #{$size},
  .#{$size},
  .font-size-#{$size} {
    font-size: var(--font-size-#{$size});
    line-height: var(--line-height-#{$size});
    letter-spacing: var(--letter-spacing-#{$size});
    margin-top: var(--spacing-top-#{$size});

    &:first-child {
      margin-top: 0;
    }
  }
}

@each $size in $sizesbold {
  #{$size},
  .#{$size},
  .font-size-#{$size} {
    font-weight: 600;
  }
}


strong,
b,
.font-weight-600 {
  font-weight: 600;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-400 {
  font-weight: 400;
}

.text-align-center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.font-color-white {
  color: var(--color-white);
}
.font-color-yellow {
  color: var(--color-warning);
}

blockquote {
  margin-top: 5rem;
  margin-bottom: 5rem;
  @extend .font-size-h2;
  quotes: "„" "”" "," "’";

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }

  @include mq.mq($from: large) {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
}

figcaption {
  padding-top: 1.5rem;
  text-align: left !important;
  @extend .font-size-h6;
}

::marker {
  
  ul & {
    display: list-item;
    content: "•";
  }
  
}

ul, ol {
  margin-left: 1em;
  margin-top: 4rem;

  @include mq.mq($from: medium) {
    margin-top: 4rem;
  }
}

li {
  padding-left: 1.3rem;

  &:not(:first-child) {
    margin-top: 0.8rem;

    @include mq.mq($from: large) {
      margin-top: 0;
    }
  }
}

p {
  margin-top: 2rem;

  @include mq.mq($from: large) {
    margin-top: 1.6rem;
  }
}

.c-text {

  &::after {
    content: "";
    display: block;
    clear: both;
  }

  .font-size-lead {
    margin-top: 4rem;
  }

  h4, .font-size-h4 {
    margin-top: 4rem;
  }

  p {
    line-height: 1.3;

    @include mq.mq($from: large) {
      margin-top: 4rem;
    } 
  }

  ul {
    li {
      padding-left: 0.9rem;

      &:not(:first-child) {
        margin-top: 0.4rem;
      }
    }

  }

  figure {
    margin-top: 8.2rem;
    max-width: 100%!important;
    height: auto;

    img {
      border-radius: 20px;
    }
  }

  figcaption {
    margin-top: 0;
  }

}