.c-page404 {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	background-color: transparent;
}

.c-page404__inner {
	text-align: center;
	max-width: 110rem;

	h5 {
		letter-spacing: 0;
		font-size: 4rem;
		line-height: 6rem;
	}

	p {
		max-width: 40rem;
		margin: 10rem auto 1rem;
	}
}
