@use "../mixins/mq";

$columns: 12;

$sizes: (
		'sm',
		'md',
		'lg'
);

.container {
	width: 100%;
	max-width: var(--container-width);
	margin-left: auto;
	margin-right: auto;

	&--narrow {
		
			--container-width: var(--container-width-narrow);

			@media screen and (max-width: 1488px) and (min-width: 1200px) {
				margin-left: calc( (100vw - 52rem)/12 + 4rem + 3.8rem);
			}
		
	}

	&--news {
		--container-width: var(--container-width-news);
	}
}

.row {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}

.row--v-align-center {
	align-items: center;
}

.row--h-align-center {
	justify-content: center;
}

.row--grid {
	--row-grid-gap: 1rem;

	display: grid;
	grid-template-columns: repeat($columns, 1fr);
	justify-content: center;
	grid-template-rows: auto;
	align-items: start;

	grid-column-gap: var(--row-grid-gap);

	@include mq.mq($from: medium) {
		grid-template-columns: repeat($columns, 1fr);
		--row-grid-gap: 2rem;
	}

	@include mq.mq($from: large) {
		grid-template-columns: repeat($columns, 1fr);
		--row-grid-gap: 4rem;
	}

	&-gap {
		grid-row-gap: var(--row-grid-gap);
	}
}



.row--reverse {
	@include mq.mq($from: medium) {
		direction: rtl;
	}

	* {
		direction: ltr;
		text-align: left;
	}
}

.col--half {
	width: 50%;
}

.wrapper {
	position: relative;
	width: 100%;
	padding-left: 2rem;
	padding-right: 2rem;

	@include mq.mq($from: large) {
		padding-left: 4rem;
		padding-right: 4rem;
	}
}

.middle {
	margin-left: auto;
	margin-right: auto;
}

.middle--medium {
	@include mq.mq($from: medium) {
		max-width: 44.8rem;
	}

	@include mq.mq($from: large) {
		max-width: 62rem;
	}
}

.middle--large {
	@include mq.mq($from: medium) {
		max-width: 56rem;
	}

	@include mq.mq($from: large) {
		max-width: 105rem;
	}
}

.middle--xlarge {
	

	@include mq.mq($from: large) {
		max-width: 108.6rem;
	}
}

@mixin generateColumns($columns, $size, $breakpointStart: null, $breakpointEnd: null) {

	@if ($breakpointStart) {

		@include mq.mq($from: $breakpointStart, $until: $breakpointEnd) {

			@for $i from 1 through $columns {
				.col-#{$size}-#{$i} {
					grid-column-end: span $i;
				}

				.offset-#{$size}-#{$i} {
					grid-column-start: $i + 1
				}

			}
		}

	} @else {

		@for $i from 1 through $columns {

			.col-#{$size}-#{$i} {
				grid-column-end: span $i;
			}

			.offset-#{$size}-#{$i} {
				grid-column-start: $i + 1;
			}

		}
	}
}

@each $size in $sizes {

	@if $size == 'sm' {
		@include generateColumns($columns, $size);
	}

	@else if $size == 'md' {
		@include generateColumns($columns, $size, medium, large);
	}

	@else if $size == 'lg' {
		@include generateColumns($columns, $size, large);
	}
}
