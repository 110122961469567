@use "../mixins/mq";
@use "vars";
@use "../mixins/background-svg";
@use "../_base/svg";

html {
	font-size: 62.5%;
	text-size-adjust: none;
	scroll-behavior: smooth;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;

	font-family: var(--bodyFontFamily, vars.$bodyFontFamily);
	font-size: var(--globalFontSize);

	background-color: var(--color-white);
	hyphens: manual;
	font-variant: none;

	//background: white url('../../images/arrow-right.svg') 0 0 repeat;
	//background-size: 20px 20px;

	word-break: break-word;
}

main {
	padding-top: var(--headerHeightSmall);
	min-height: calc(100vh - var(--headerHeightSmall));

	@include mq.mq($from: large) {
		padding-top: var(--headerHeight);
	}
}

[data-page="home"] #main {
	@include background-svg.params(svg.$svg-bg-main, transparent, center, center, no-repeat, 100%, 100%, '');
	background-size: cover;
}

[data-page="subpages"] #main {
	@include background-svg.params(svg.$svg-bg-detail, transparent, center, top, no-repeat, 100%, 100%, '');
	background-size: cover;
}

[data-page="news"] #main {
	background: var(--color-success);
}