@use "../_config/colors";
@use "../mixins/mq";

:root {
  --container-width: 63rem;
  --container-width-narrow: var(--container-width);
  --container-width-news: var(--container-width);

  --globalFontSize: 1.8rem;
  --globalLineHeight: 1.5;

  --color-black: #{colors.$color-black};
  --color-grey-400:  #{colors.$color-grey-400};
  --color-grey-300:  #{colors.$color-grey-300};
  --color-grey-200:  #{colors.$color-grey-200};
  --color-grey-150:  #{colors.$color-grey-150};
  --color-grey-100:  #{colors.$color-grey-100};
  --color-white:  #{colors.$color-white};
  --color-background-white: #{colors.$color-background-white};
  --color-primary-1:  #{colors.$color-primary-1};
  --color-primary-200:  #{colors.$color-primary-200};
  --color-primary-2:  #{colors.$color-primary-2};
  --color-secondary-1:  #{colors.$color-secondary-1};
  --color-secondary-2:  #{colors.$color-secondary-2};
  --color-error:  #{colors.$color-error};
  --color-success:  #{colors.$color-success};
  --color-warning:  #{colors.$color-warning};
  --color-error-hover:  #{colors.$color-error-hover};
  --color-success-hover:  #{colors.$color-success-hover};
  --color-warning-hover:  #{colors.$color-warning-hover};
  --color-black-transparent:  #{colors.$color-black-transparent};
  --color-primary-1-transparent:  #{colors.$color-primary-1-transparent};

  --font-size-h1: 4rem;
  --font-size-h2: 3.3rem;
  --font-size-h3: 2.8rem;
  --font-size-h4: 2.3rem;
  --font-size-h5: 1.6rem;
  --font-size-h6: 1.3rem;
  --font-size-lead: 1.9rem;

  --line-height-h1: 1.31;
  --line-height-h2: 1.37;
  --line-height-h3: 1.53;
  --line-height-h4: 1.53;
  --line-height-h5: 1.47;
  --line-height-h6: 1.6;
  --line-height-lead: 1.3;

  --letter-spacing-h1: 0;
  --letter-spacing-h2: 0;
  --letter-spacing-h3: 0.65;
  --letter-spacing-h4: 0.65;
  --letter-spacing-h5: 0.7;
  --letter-spacing-h6: 0;

  --spacing-top-h1: 5rem;
  --spacing-top-h2: 4rem;
  --spacing-top-h3: 3rem;
  --spacing-top-h4: 2rem;
  --spacing-top-h5: 2rem;
  --spacing-top-h6: 2rem;

  --font-size-button: 2.4rem;

  --button-primary-min-height: 5.1rem;
  --button-primary-min-width: 25.5rem;

  --bodyFontFamily: "IsidoraSans", Helvetica, Arial, sans-serif;
  --bodyFontColor: var(--color-black);

  --headerHeightSmall: 8rem;
  --headerHeight: 12rem;

  --hamburgerLineHeight: 2px;
  --hamburgerWidth: 50px;
  --hamburgerHeight: 25px;
  --hamburgerSpacing: 12px;

  --formBorder: 1px solid #999;
  --formBorderRadius: 0;
  --formInputHeight: 33px;

  --animationDuration: 0.25s;
  --animationEase: cubic-bezier(.25,.46,.45,.75);

  --shadow: 0.2rem 0.4rem 0.8rem 0 rgba(19, 22, 28, 0.05);

  @include mq.mq($from: large) {

    --container-width: 140.8rem;
    --container-width-narrow: 108.7rem;
    --container-width-news: 94rem;
    --globalFontSize: 1.8rem;

    --font-size-h1: 5.8rem;
    --font-size-h2: 4.6rem;
    --font-size-h3: 3.7rem;
    --font-size-h4: 3rem;
    --font-size-h5: 1.9rem;
    --font-size-h6: 1.5rem;
    --font-size-lead: 2.4rem;

    --line-height-h1: 1.12;
    --line-height-h2: 1.3;
    --line-height-h3: 1.3;
    --line-height-h4: 1.3;
    --line-height-h5: 1.3;
    --line-height-h6: 1.3;
    --line-height-lead: 1.3;

    --letter-spacing-h1: 0;
    --letter-spacing-h2: 0;
    --letter-spacing-h3: 0.8;
    --letter-spacing-h4: 0.65;
    --letter-spacing-h5: 0.85;
    --letter-spacing-h6: 0;

    --spacing-top-h1: 6rem;
    --spacing-top-h2: 6rem;
    --spacing-top-h3: 3rem;
    --spacing-top-h4: 3rem;
    --spacing-top-h5: 3rem;
    --spacing-top-h6: 3rem;
  }
}

$bodyFontFamily: "IsidoraSans", Helvetica, Arial, sans-serif;

@media (prefers-reduced-motion: reduce) {
  :root {
    --animationDuration: 0;
    --animationEase: none;
  }
}
