@use "../mixins/mq";
@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";

.filter-top {
    position: relative;
    &.loading {
        &:after {
            content: "";
            font-size: 2rem;
            font-weight: 600;
            display: flex;
            width: 100%;
            background: rgba(255, 255, 255, 0.5);
            position: absolute;
            inset: 0;
            align-items: center;
            justify-content: center;
        }
    }

    &__block {
        margin-top: 4rem;

        &:last-child {
            margin-top: 2.4rem;
        }

        @include mq.mq($from: large) {
            &:last-child {
                margin-top: 4rem;
            }
        }
    }

    &__title {
      margin-bottom: .8rem;
      line-height: 1.5; 
    }

    &__when {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @include mq.mq($from: large) {
            align-items: center;
        }
    }

    &__calendar-wrapper {
        display: flex;
        align-items: center;

        &:after {
            content: '';
            width: 2rem;
            height: 1.9rem;
            display: inline-block;
            transform: translateX(-36px);
            pointer-events: none;

            @include background-svg.params(svg.$svg-calendar, transparent, center, center, no-repeat, 2rem, 1.9rem, colors.$color-primary-1);
        }
    }

    &__calendar {
        width: 21.7rem;
        font-size: 1.8rem;
        line-height: 1.6;
        border: 1px solid var(--color-primary-1);
        padding: 0.9rem 3.6rem 0.9rem 1.6rem;
        border-radius: 2.5rem;

        &::placeholder {
            color: var(--color-black);
        }

        @include mq.mq($from: large) {
            width: 27.7rem;
        }
        
    }

    &__view {
        --icon-size: 3rem;
        --margin-top: 1rem;

        display: block;
        width: var(--icon-size);
        height: var(--icon-size);
        cursor: pointer;
        margin-top: var(--margin-top);

        &[data-view='cards'] {
            @include background-svg.params(svg.$svg-table, transparent, center, center, no-repeat, 100%, 100%, colors.$color-primary-1);
        }

        &[data-view='table'] {
            @include background-svg.params(svg.$svg-cards, transparent, center, center, no-repeat, 100%, 100%, colors.$color-primary-1);            
        }   

        @include mq.mq($from: large) {
            --icon-size: 4rem;
            --margin-top: 0;
        }

    }

    &__date-clear {
        color: var(--color-primary-200);
        font-size: 1.8rem;
        line-height: 1.6;
        text-decoration: underline;
        text-underline-offset: 0.2rem;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }

        &[data-clear="false"] {
            display: none;
        }
    }
}

.filter-result {
    --events-grid-cols: 1;
    --events-grid-gap: 1.6rem;

    display: grid;
    grid-template-columns: repeat(var(--events-grid-cols), 1fr);
    gap: var(--events-grid-gap);
    margin-top: 2.3rem;

    @include mq.mq($from: medium) {
        --events-grid-cols: 2;
    }

    @include mq.mq($from: large) {
        --events-grid-cols: 4;
        --events-grid-gap: 4rem;

        margin-top: 4rem;
    }

    &[data-view="cards"] {
        .table-header {
            display: none;
        }
    }

    &[data-view="table"] {
        --events-grid-cols: 1;
        --events-grid-gap: 1.6rem;

        .heading__card {
            display: grid;
            grid-template-columns: 11.4rem auto;
            padding: .8rem 1.6rem .8rem .8rem;
            grid-template-areas: ". a";
            gap: 0 1.6rem;


            @include mq.mq($from: large) {
                align-items: center;
                grid-template-columns: 11.4rem 40rem 19rem 11rem auto 2rem;
                gap: 0 2.4rem;
                padding: .8rem 4rem .8rem .8rem;
            }
            
        }

        .figure-heading-events {
            width: 11.4rem;
            height: 11.4rem;
            border-radius: .5rem;

            picture, img {
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
            }

            img {
                object-fit: cover;
            }
        }

        .event__title {
            margin-top: 0;
            max-width: 40rem;
            grid-area: a;
            
            @include mq.mq($until: large) {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        .event__location {
            @include mq.mq($until: large) {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: block;
            }
        }

        .event__meta {
            grid-area: a;
            gap: 0;
            margin-top: 2.1rem;

            @include mq.mq($from: large) {
                grid-column: 3 / 7;
                grid-template-columns: subgrid;
                margin-top: 0;
                gap: 4rem;
            }

            .icon {
                display: none;
            }

            .event__calendar-save {
                align-items: center;
                justify-content: end;

                span {
                    display: none;
                }

                .icon {
                    display: block;
                }
            }
        }

        
    }
}

.table-header {
    display: none;
    grid-template-columns: 11.4rem 40rem 19rem 11rem auto 2rem;
    gap: 0 2.4rem;
    padding: .8rem;
    color: var(--color-white);
    font-weight: 600;
    line-height: 1.5;
    background-color: var(--color-black);
    border-radius: 1rem;
    grid-template-areas: ". a b c d .";

    @include mq.mq($from: large) {
        display: grid;
    }

    > div {
        display: flex;
        gap: .8rem;
        align-items: center;
    }

    &__theme {
        grid-area: a;
    }

    &__date {
        grid-area: b;
    }

    &__time {
        grid-area: c;
    }

    &__location  {
        grid-area: d;
    }
}

.flatpickr {
    display: flex;
    flex-direction: column;
    align-items: start;

    @include mq.mq($from: large) {
        flex-direction: row;
        align-items: center;
    }
}

.flatpickr-monthSelect-months {
    margin: 10px 1px 15px 1px;
    flex-wrap: wrap;
  }
  
  .flatpickr-monthSelect-month {
    background: none;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #000000;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    justify-content: center;
    padding: 0 0.8rem 0.4rem;
    position: relative;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    text-align: center;
    width: 33%;
    font-size: 1.8rem;
    line-height: 1.6;
    max-width: 6.8rem;
    margin: 0.5rem 1rem;
  }
  
  .flatpickr-monthSelect-month.flatpickr-disabled {
      color: #eee;
  }
  
  .flatpickr-monthSelect-month.flatpickr-disabled:hover,
  .flatpickr-monthSelect-month.flatpickr-disabled:focus {
      cursor: not-allowed;
      background: none !important;
  }
  
  .flatpickr-monthSelect-theme-dark {
    background: #3f4458;
  }
  
  .flatpickr-monthSelect-theme-dark .flatpickr-current-month input.cur-year {
    color: #fff;
  }
  
  .flatpickr-monthSelect-theme-dark .flatpickr-months .flatpickr-prev-month,
  .flatpickr-monthSelect-theme-dark .flatpickr-months .flatpickr-next-month {
    color: #fff;
    fill: #fff;
  }
  
  .flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month {
    color: rgba(255, 255, 255, 0.95);
  }
  
  .flatpickr-monthSelect-month.today {
    border-color: #000000;
  }
  
  .flatpickr-monthSelect-month.inRange,
  .flatpickr-monthSelect-month.inRange.today,
  .flatpickr-monthSelect-month:hover,
  .flatpickr-monthSelect-month:focus {
    background: #e6e6e6;
    cursor: pointer;
    outline: 0;
    border-color: #e6e6e6;
  }
  
  .flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month.inRange,
  .flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month:hover,
  .flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month:focus {
    background: #646c8c;
    border-color: #646c8c;
  }
  
  .flatpickr-monthSelect-month.today:hover,
  .flatpickr-monthSelect-month.today:focus {
    background: #959ea9;
    border-color: #959ea9;
    color: #fff;
  }
  
  .flatpickr-monthSelect-month.selected,
  .flatpickr-monthSelect-month.startRange,
  .flatpickr-monthSelect-month.endRange {
    background-color: var(--color-primary-1);
    box-shadow: none;
    color: #fff;
    border-color: var(--color-primary-1);
  }
  
  .flatpickr-monthSelect-month.startRange {
    border-radius: 50px 0 0 50px;
  }
  
  .flatpickr-monthSelect-month.endRange {
    border-radius: 0 50px 50px 0;
  }
  
  .flatpickr-monthSelect-month.startRange.endRange {
    border-radius: 50px;
  }
  
  .flatpickr-monthSelect-month.inRange {
    border-radius: 0;
    box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  }
  
  .flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month.selected,
  .flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month.startRange,
  .flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month.endRange {
    background: #80cbc4;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    border-color: #80cbc4;
  }
  
.flatpickr-mode {
    position: absolute;
    top: 1.5rem;
    display: flex;
    margin-inline: 7rem;
    justify-content: space-between;
    gap: 1.5rem;

    button {
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 1.5;
        padding: 0 0.8rem 0.4rem;
        color: var(--color-black);
        background: transparent;
        border-radius: 0.4rem;

        &[data-active="true"] {
            color: var(--color-white);
            background: var(--color-primary-1);
        }
    }
}

.flatpickr-months {
    margin-top: 5rem;
    position: relative;
}


@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/**
    * Spinning dots - scaled
    *
    * @author jh3y - https://github.com/jh3y
  */
.spinning-dots-scaled {
    position: absolute;
    left: 50%;
    top: 50%;
    display: none;

    .loading & {
        display: block;
    }

    &:before {
        --primary: var(--color-black);

        animation: spin 1.5s infinite linear;
        border-radius: 8px;
        box-shadow: 20px 0 0 -4px var(--primary), 12.4698px 15.63663px 0 -4px var(--primary), -4.45042px 19.49856px 0 -3px var(--primary), -18.01938px 8.67767px 0 -2px var(--primary), -18.01938px -8.67767px 0 -1px var(--primary), -4.45042px -19.49856px 0 1px var(--primary), 12.4698px -15.63663px 0 2px var(--primary);
        content: '';
        height: 0.8rem;
        width: 0.8rem;
        display: block;
    }
}

  