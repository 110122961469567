@use "../mixins/mq";
@use "../_base/typography";

.filter__date {

    display: flex;

    &--link {
        font-size: 1.9rem;
        line-height: 1.3;
        padding: 0 20px 6px;
        text-decoration: none;
        color: var(--color-black);

        &.active {
            background-color: var(--color-black);
            color: var(--color-white);
            font-weight: 600;
            border-radius: 20px;

        }
    }

    .filter__select {
        background-color: var(--color-black);
        color: var(--color-white);
        font-weight: 600;
        border-radius: 20px;
        font-size: 1.9rem;
        line-height: 1.3;
        padding: 0 20px 6px;
        text-decoration: none;

    }

    .hide-on-mobile {
        display: none;

        @include mq.mq($from: large) {
            display: flex;
        }
    }

    .show-on-mobile {
        display: flex;

        @include mq.mq($from: large) {
            display: none;
        }
    }
    
    @include mq.mq($until: large) {

        &--link {
            visibility: hidden;
            transition: 0.3s ease-in-out;
            opacity: 0;
            height: 0;
            padding-bottom: 0;
            border-bottom: none;
        }

        &.open {

            .filter__date--link {
                visibility: visible;
                opacity: 1;
                height: 32px;
            }
        }
    }

    @include mq.mq($from: large) {

        border: 1px solid var(--color-black);
        border-radius: 20px;
        background-color: var(--color-white);
        width: fit-content;

        &--link {
            font-size: 2.4rem;
            line-height: 1.3;
            padding: 0 20px 6px;
            text-decoration: none;
            color: var(--color-black);

            &.active {
                background-color: var(--color-black);
                color: var(--color-white);
                font-weight: 600;
                border-radius: 20px;

            }
        }

        &.filter__wrapper {
            padding-left: 0;
        }
    }

}