@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_base/svg";
@use "../_base/typography";

.filter__wrapper {
    padding-left: 0;
    min-height: 4rem;
    
    @include mq.mq($until: large) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .action-tag {
            margin-left: 0;

            &:not(:first-child) {
                visibility: hidden;
                transition: 0.3s ease-in-out;
                opacity: 0;
                height: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
        }

        &.open {

            .tag-item {
                visibility: visible;
                opacity: 1;
                height: 32px;

                &:last-child {
                    margin-bottom: 10px;
                }
            }
        }
    }

    @include mq.mq($from: large) {
        padding-left: 4.2rem;
    }

    &--magazin {
        padding-left: 0;
    }

    &--agenda {
        display: flex;
        flex-direction: column;

        .tag-item {
            order: 1;
            height: 0;
            visibility: hidden;
            opacity: 0;

            @include mq.mq($from: large) {
                visibility: visible;
                opacity: 1;
                height: auto;
            }
        }

        .field__input--radio {
            &:checked + {
                .action-tag {
                    background-color: var(--color-primary-1);
                    color: var(--color-white);
                    font-weight: 600;
                    border-color: var(--color-primary-1);
                    padding-bottom: 0.6rem;
                }
            }
        }
        

        .action-tag { 
            margin-left: 0;
            order: 1;
            white-space: nowrap;
            cursor: pointer;
            
            
            
            @include mq.mq($from: large) {
                background-color: var(--color-background-white);
                border: 1px solid var(--color-black);
                color: var(--color-black);
                font-weight: 500;

                &:hover {
                    background-color: var(--color-primary-1);
                    border-color: var(--color-primary-1);
                    color: var(--color-white);
                }
            }
            
            @include mq.mq($until: large) {
                &.tag-item {
                    visibility: hidden;
                    transition: 0.3s ease-in-out;
                    opacity: 0;
                    height: 0;
                    padding-bottom: 0;
                    border-bottom: none;
                    background: transparent;
                    color: var(--color-black);
                }

            } 

            &.filter__mobile {
                display: flex;
                align-items: flex-end;
                padding-bottom: 6px;
                margin-bottom: 1rem;
                visibility: visible;
                opacity: 1;
                height: 32px;
                order: 0;

                &:after {
                    content: '';
                    width: 10px;
                    height: 8px;
                    @include background-svg.params(svg.$svg-arrow-down, transparent, center, center, no-repeat, 100%, 100%, var(--color-white));
                    display: block;
                    margin-left: 6px;
                    margin-bottom: 5px;
                    transition: transform 0.2s ease-in-out;
                }

                @include mq.mq($from: large) {
                    display: none;
                }
            }
        
         }

         &.open {

            .tag-item {
                @include mq.mq($until: large) {
                    visibility: visible;
                    opacity: 1;
                    height: 32px;
                    background: transparent;
                    color: var(--color-black);
                    font-weight: 400;

                    &:last-child {
                        margin-bottom: 10px;
                    }
                }
            }

            .filter__mobile {
                &:after {
                    transform: rotate(-180deg);
                }
            }
        }

        @include mq.mq($from: large) {
            padding-left: 0;
            flex-direction: row;
            gap: 2.4rem;
        }
    }
}

.filter-items__magazin {
    .preview-image {
        min-height: 19rem;
    }
}

.filter__select {
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;

    &::after {
        content: '';
        width: 10px;
        height: 8px;
        @include background-svg.params(svg.$svg-arrow-down, transparent, center, center, no-repeat, 100%, 100%, var(--color-white));
	    display: block;
        margin-left: 6px;
        margin-bottom: 5px;
        transition: transform 0.2s ease-in-out;
    }

    .open & {
        &::after {
            transform: rotate(-180deg);
        }
    }

    @include mq.mq($from: large) {
        display: none;
    }
}

.filter-items {
    margin-top: 4rem;
    width: 100%;

    &:not(.filter__placehoder) {
        .filter-items__list--placehoder {
            display: none !important;
        }
    }


    @include mq.mq($from: large) {
        margin-top: 15.5rem;
        --spacing-top-h2: 3.8rem;
        max-width: 108.8rem;
        display: flex;
        flex-direction: column;
    }

    &__list {

        grid-row-gap: 6rem;

        @include mq.mq($until: large) {
            display: grid;
        }

        @include mq.mq($from: medium) {
            
            grid-column-gap: 6rem;
        }

        @include mq.mq($from: large) {
            grid-row-gap: 4rem;
            grid-column-gap: 4rem;
        }

        [data-page="news"] & {
            grid-row-gap: 2rem;
            
            @include mq.mq($from: medium) {
                grid-row-gap: 4rem;
                grid-column-gap: 4rem;
            }
        }
     
        
    }

    &__agenda {

        @include mq.mq($from: medium) {
            
            grid-column-gap: 3.5rem;
        }
        @include mq.mq($from: large) {
            
            grid-row-gap: 3.5rem;
            grid-column-gap: 3.5rem;
        }
    }

    &--tile {
        margin: 0;
        max-width: none;
    }

    article {
       // max-width: 345px;
        transition: 0.1s ease-in-out;
        position: relative;
        height: 100%;

        @include mq.mq($from: medium) {
         //   max-width: 293px;

            
        }
        
    
        @include mq.mq($from: large) {
        //    max-width: 394px;
          //   margin-bottom: 8rem;
            
          padding-bottom: 40px;

            &:nth-child(even) {
         //       margin-left: auto;
            }
            &:not(:first-child) {
                margin-top: -27.7rem;
            }
        }

        &:hover {
            background-color: #ABD1B7;
            -webkit-box-shadow: 0px 5px 0px 30px #ABD1B7; 
            box-shadow: 0px 0px 0px 30px #ABD1B7;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }

        
    }

}

.preview-image {
   // max-width: 345px;
    border-radius: 40px;
    overflow: hidden;
    min-height: 215px;

    &--empty::before {
        content: "";
        display: block;
        width: 100%;
        height: inherit;
        background-color: rgba(0,0,0,0.2);
        position: absolute;
        border-radius: 40px;
        height: 215px;

        @include mq.mq($from: medium, $until: large) {
            height: 189px;
        }
         
        @include mq.mq($from: large) {
            height: 222px;
        }
    }

    @include mq.mq($from: medium, $until: large) {
   //     max-width: 293px;
        min-height: 189px;
    }

    @include mq.mq($from: large) {
   //     max-width: 394px;
        min-height: 222px;
    }

}

.preview-text {
    margin-top: 1.3rem;

    @include mq.mq($from: large) {
        margin-top: 1.3rem;
    }
}

.personal {

    &__meta {
        @extend .font-size-h5;

        &--email {
            margin-top: 0;
        }

        .personal-link {
            color: var(--color-black);
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        &--contact {
            .action {
                width: 100%;
                min-width: 100%;
            }
        }
        
    }
}