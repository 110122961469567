@use "../mixins/mq";

.lang-chooser {
	position: absolute;
	left: 2rem;
	top: 2.5rem;

	@include mq.mq($from: large) {
		position: static;
	}
}

.lang-chooser__item {
	position: relative;
	&:not(:first-child) {
		padding-left: 2rem;

		&:before {
			position: absolute;
			left: 0;
			top: -3px;
			display: block;
			width: 2rem;
			text-align: center;
			content: '|';
			line-height: normal;
		}
	}

	.nav__link {
		.not-touch & {
			&:hover {
				color: var(--color-primary-1);
			}
		}
	}
}
