@use "../mixins/mq";
@use "../_base/svg";
@use "../mixins/background-svg";

.tile {
    --outline-color: var(--color-black);

    background-color: var(--tile-bg-color, #EDCD00);
    color: inherit;
    aspect-ratio: 1/1;
    display: grid;
    align-items: end;
    justify-items: start;
    text-decoration: none;
    transition: background-color var(--animationDuration) var(--animationEase);
    cursor: pointer;
    position: relative;
    overflow: hidden;

    @include mq.mq($from: large) {
        --tile-overlay-margin: 4rem;
    }
    
    &--placeholder {
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: placeHolderShimmer;
            animation-timing-function: linear;
            background: #f6f7f8;
            background: linear-gradient(to right,  #EDCD00  8%, var(--color-warning-hover) 18%,  #EDCD00 33%);
            background-size: 1000px 104px;
            position: relative;
            overflow: hidden;
    }

    &--gradient {
        color: var(--color-white);
   
        &:before {
            content: "";
            z-index: 1;
            position: absolute;
            left: 0;
            bottom: 0;
            top: 0;
            right: 0;
            background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 8.33%, rgba(0, 0, 0, 0.6) 68.51%), ;
        }
    }

    &:hover,
    &:focus {
        --tile-bg-color: var(--color-warning-hover);
        --tile-header-text-decoration: underline;
    }

    &__cover {
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        right: 0;
    }

    &__overlay {
        margin: var(--tile-overlay-margin, 1.4rem);
        font-weight: 600;
        position: relative;
        z-index: 1;

        @include mq.mq($from: large) {
            margin: var(--tile-overlay-margin, 2.4rem);
        }
    }

    &__category {
        text-transform: uppercase;
        margin-bottom: 9px;
        display: block;
        font-size: 1.9rem;
        line-height: 1.3;
        letter-spacing: 2px;
        &:empty {
            display: none;
        }
    }

    &__header {
        margin: .5rem 0 0;
        text-decoration-line: var(--tile-header-text-decoration);
        text-underline-offset: 4px;
        text-decoration-thickness: 1px;
        line-height: 1.3;
        hyphens: auto;

        @include mq.mq($from: large) {
            text-underline-offset: 5px;
            text-decoration-thickness: 2px;
        }
    }

}

[data-page="news"] {
	.text-white {
        color: var(--color-white);
    }

    .h1 {
        margin-bottom: 3.6rem;

        @include mq.mq($from: large) {
            margin-bottom: 7.3rem;
        }
    }

    .filter__wrapper {
        padding-left: 0;
        margin-bottom: 8rem;

        @include mq.mq($from: large) {
            margin-bottom: 16rem;
        }
    }

    .action-tag {

        &[data-active=false] {
            @include mq.mq($until: large) {
                color: var(--color-white);
            }
        }

        @include mq.mq($from: large) {
            background-color: var(--color-grey-150);
            color: var(--color-black);
            border-color: var(--color-grey-150);

            &[data-active="true"] {
                color: var(--color-white);
                background-color: var(--color-black);
                font-weight: 500;
                border-color: var(--color-black);
            }
        }
        
    }

    .bg-news-image {
        position: relative;

        &::after {
            content: unset;

            @include mq.mq($from: large) {
                content: '';
                width: 31rem;
                height: 29.6rem;
                display: block;
                position: absolute;
                right: 0;
                top: -5rem;
                @include background-svg.params(svg.$svg-news-bg, transparent, right, center, no-repeat, 31rem, 29.6rem, transparent);
            }
            
        }
    }
}

.c-newsTeaser {
    @include mq.mq($until: large) {
        --container-width: 100%;
    }
}