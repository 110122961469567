@use "../mixins/mq";
@use "../utils/visually-hidden";

.header {
  position: fixed;
  z-index: 50;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: var(--headerHeightSmall);
  background-color: var(--color-white);

  box-shadow: 0 2px 20px 0 #1A191920;
  transition: transform var(--animationDuration) var(--animationEase);

  @include mq.mq($from: large) {
    height: var(--headerHeight);
  }
}

.header--scroll {
  transform: translateY(-120%);
}

.header__home-link {
  display: inline-flex;
  margin-left: 1.6rem;


  @include mq.mq($from: large) {
    margin-left: 4rem;
  }

  > * {
    width: 40px;

    @include mq.mq($from: large) {
      width: 279px;
    }
  }
}

.header__home-link-text {
  @extend .visually-hidden;
}

.header__nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  margin-top: var(--headerHeightSmall);
  padding-top: 1rem;
  padding-bottom: 5rem;
  z-index: 1;
  transform: translate3d(100%, 0, 0);
  background-color: var(--color-white);
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  transition: transform var(--animationDuration) var(--animationEase);

  @include mq.mq($from: large) {
    position: relative;
    position: unset;
    height: auto;
    display: flex;
    justify-content: center;
    padding: 4rem 4rem 0 0;
    margin-top: 0;
    transform: none;
    background: none;
    overflow: visible;
  }

  .header__nav--opened & {
    transform: translate3d(0, 0, 0);

    @include mq.mq($from: medium) {

    }
    @include mq.mq($from: large) {
      transform: none;
    }
  }
}

.header__nav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-black-transparent);
  display: none;
  z-index: 0;

  .header__nav--opened & {
    @include mq.mq($from: medium, $until: large) {
      display: none;
    }
  }
}


.header__nav-meta {
  @include mq.mq($from: large) {
    position: absolute;
    right: 0;
    top: 0.8rem;
    padding-right: 4rem;
  }
}

.header__hamburger {
  position: relative;
  margin-right: 1.6rem;
  z-index: 2;

  @include mq.mq($from: large) {
    display: none;
    visibility: hidden;
  }
}
.icon-logo.icon-logo {
  display: none;

  @include mq.mq($from: large) {
    display: block;
  }
}