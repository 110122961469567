@use "../mixins/mq";

.text-icon {
    margin-bottom: 4rem;

    &__title {
        margin-bottom: 4rem;

    }

    &__icon {
        border-radius: 20px;
        width: 8rem;
        height: 8rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mq.mq($from: medium) {
            width: 10rem;
            height: 10rem;
        }

        @include mq.mq($from: large) {
            width: 12rem;
            height: 12rem;
        }

        &.color {
            &--white {
                color: var(--color-white);
            }
            &--black {
                color: var(--color-black);
            }
        }

        &.bg-color {
            &--red {
                background-color: #D8503F;
            }
            &--green {
                background-color: #4B8176;
            }
            &--yellow {
                background-color: #F4DE7C;
            }
            &--grey {
                background-color: #E6E6E6;
            }
        }

        .icon {
            max-width: 4rem;

            @include mq.mq($from: medium) {
                max-width: 5rem;
            }
    
            @include mq.mq($from: large) {
                max-width: 6rem;
            }
        }
    }

    &__text {
        margin-top: 1.6rem;

        @include mq.mq($from: large) {
            margin-top: 2.4rem;
        }
    }
}
