.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background-color: var(--color-primary-1);
  border-color: var(--color-primary-1);
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 var(--color-primary-1);
}