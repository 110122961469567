@use "../mixins/mq";

.section {
	position: relative;
	overflow: hidden;

	&--success {
		background-color: var(--color-success);
	}

	&--inverted {
		color: var(--color-white);
	}
}

.section--spacing {
	padding-top: 4rem;
	padding-bottom: 4rem;

	@include mq.mq($from: large) {
		padding-top: 8rem;
		padding-bottom: 8rem;

		.c-first & {
			padding-top: 4rem;
		}
	}

	.component__heading & {
		padding-top: 0;

		@include mq.mq($from: large) {
			padding-bottom: 0;
		}
	}
}


.section--spacing-top {
	padding-top: 4rem;

	@include mq.mq($from: large) {
		padding-top: 8rem;
	}
}

.section--spacing-bottom {
	padding-bottom: 4rem;

	@include mq.mq($from: large) {
		padding-bottom: 8rem;
	}
}


.section__title {
	margin-bottom: 4rem;
}
