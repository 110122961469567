@use "../mixins/mq";

.breadcrumb__wrapper {
	display: none;
	visibility: hidden;

	@include mq.mq($from: large) {
		display: flex;
		visibility: visible;
	}
}

.breadcrumb {
	display: flex;
	margin-left: 0;

}

.breadcrumb__wrapper {
	position: relative;
	z-index: 2;
	max-width: 100vw;
	white-space: nowrap;
	overflow: auto;
	height: 80px;
	//overflow-y: hidden;
	padding: 0;
	-webkit-overflow-scrolling: touch;
}

.breadcrumb--child {
	position: fixed;
	left: -22px;
	top: 100%;
	display: none;
	//display: flex;
	flex-direction: column;
	width: auto;
	max-width: 100%;
	/*min-width: calc(100% + 25px);*/
	padding: 1rem 0;
	background-color: var(--color-grey-300);
	word-break: keep-all;
	white-space: nowrap;
}

.breadcrumb__item {
	//position: relative;
	display: flex;
	align-items: center;
	font-size: 1.8rem;
	height: 30px;
	overflow: visible;
	letter-spacing: 0.1rem;
	padding-left: 0.7rem;

	&:first-child {
		padding-left: 0;
	}

}

.breadcrumb__trigger {
	height: 30px;
	margin-left: var(--spacingSmall);
	color: var(--color-grey-300);

	.breadcrumb--light & {
		color: var(--color-white);
	}

	.icon {
		transform-origin: center center;
	}
}

.breadcrumb__item-title {
	display: inline-flex;
	align-items: center;
	height: 30px;
	color: var(--color-black);
	font-size: 1.8rem;
	text-underline-offset: 1px;

	.breadcrumb--light & {
		color: var(--color-white);
	}
}

a.breadcrumb__item-title {
	text-decoration: underline;
	.not-touch & {
		&:hover {
			text-decoration: none;
		}
	}
}

.breadcrumb__item--home {
	margin-left: 0;
}

.breadcrumb__link--home {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: var(--color-grey-300);
}

.breadcrumb__item--last {
	padding-right: var(--spacingRegular);
	color: var(--color-grey-300);
	cursor: default;
}

.breadcrumb__item--opened {
	.breadcrumb--child {
		display: flex;
	}

	.breadcrumb__trigger {
		.icon {
			transform: rotate(180deg);
		}
	}
}

[data-page="news"] {

	.breadcrumb__item {
		color: var(--color-white);
		
		&-title {
			color: var(--color-white);
		}
	}
	
}