@use "../mixins/mq";

.mt-image {
    margin-top: 2.4rem;

    @include mq.mq($from: large) {
        margin-top: 1.6rem;
    }
}

.c-kopfmodul {
    --spacing-top-h1: 2.6rem
}

.kopfmodul__text {
    padding-left: 0;
    min-height: 4rem;

    @include mq.mq($from: large) {
        padding-left: 3.8rem;
    }
}

.events {
    &__files {
        display: flex;
        gap: 2rem;
        flex-direction: column;
        margin-top: 2rem;

        @include mq.mq($from: medium) {
            flex-direction: row;
        }

        .action {
            font-size: 1.8rem;
            
            @include mq.mq($from: medium) {
                font-size: 2.4rem;
            }
        }
    }
}