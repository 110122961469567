@use "../_base/svg";
@use "../_config/colors";
@use "../_base/typography";
@use "../mixins/background-svg";
@use "../mixins/mq";

.action {
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	max-width: 100%;
	overflow: visible;
	text-decoration: none;
	transition: background-color var(--animationDuration) var(--animationEase), color var(--animationDuration) var(--animationEase), border-color var(--animationDuration) var(--animationEase);
	cursor: pointer;
}

.action-primary {
	min-height: var(--button-primary-min-height);
	min-width: var(--button-primary-min-width);
	padding: 0 2rem;
	border-radius: 4px;

	font-size: var(--font-size-button);
	@extend .font-weight-600;
	color: var(--color-white);

	background-color: var(--color-primary-1);
	border: 1px solid var(--color-primary-1);

	&.disabled {
		background-color: var(--color-grey-200);
		border-color: var(--color-grey-200);
	}

	.not-touch & {
		&:hover {
			color: var(--color-primary-1);
			background-color: var(--color-white);
		}
	}

	.field-group--submit & {
		border-radius: 4px;
		width: 100%;
		height: 6.6rem;
		padding: 0 0 1rem 0;
		@extend .font-size-h3;
		margin: 0 auto;

		@include mq.mq($from: medium) {
			width: 85%;
		}
	}
}

.action-primary--inverted {
	@extend .action-primary;
	color: var(--color-primary-1);

	border-color: var(--color-white);
	background-color: var(--color-white);

	&.disabled {
		color: var(--color-white);
	}

	.not-touch & {
		&:hover {
			color: var(--color-white);
			background: none;
		}
	}
}


.action-secondary {
	min-height: 4.2rem;
	min-width: 29.5rem;
	padding: 0 1.5rem;

	font-size: 1.9rem;
	font-weight: 500;
	color: var(--color-black);
	background-color: var(--color-white);

	border-radius: 4px;
	border: 1px solid var(--color-black);

	@include mq.mq($from: large) {
		min-height: var(--button-primary-min-height);
		min-width: var(--button-primary-min-width);
		font-size: var(--font-size-button);
		border: 2px solid var(--color-black);
	}

	&.disabled {
		color: var(--color-grey-200);
		border-color: var(--color-grey-200);
	}

	.not-touch & {
		&:hover {
			background-color: var(--color-grey-100);
		}
	}

	.font-color-white & {
		@extend .action-secondary--inverted;
	}
}

.action-secondary--inverted {
	@extend .action-secondary;
	color: var(--color-black);

	border-color: var(--color-black);
	background: none;

	&.disabled {
		color: var(--color-grey-200);
	}

	.not-touch & {
		&:hover {
			background-color: var(--color-grey-100);
		}
	}
}

.action-fab {
	width: 6rem;
	height: 6rem;
	border-radius: 50%;
	@include background-svg.params(svg.$svg-arrow-right, transparent, center, center, no-repeat, 3rem, 2.1rem, colors.$color-primary-1);
	border: 0.1rem solid var(--color-primary-1);

	.not-touch & {
		&:hover {
			@include background-svg.params(svg.$svg-arrow-right, colors.$color-primary-1, center, center, no-repeat, 3rem, 2.1rem, colors.$color-white);
		}
	}

	.not-touch .c-three_columns-item:hover & {
		@include background-svg.params(svg.$svg-arrow-right, colors.$color-primary-1, center, center, no-repeat, 3rem, 2.1rem, colors.$color-white);
	}

	&--inverted {
		@include background-svg.params(svg.$svg-arrow-right, transparent, center, center, no-repeat, 3rem, 2.1rem, colors.$color-white);
		border-color: var(--color-white);

		.not-touch & {
			&:hover {
				border-color: var(--color-primary-1);
			}
		}
	}

	&.disabled {
		@include background-svg.params(svg.$svg-arrow-right, transparent, center, center, no-repeat, 3rem, 2.1rem, colors.$color-grey-200);
		border-color: var(--color-grey-200);
	}
}

.action-download {
	@extend .action;
	font-size: var(--font-size-button);
	@extend .font-weight-600;
	color: var(--color-black);

	&:before {
		width: 4rem;
		height: 4rem;
		content: '';
		margin-right: 1rem;
		@include background-svg.params(svg.$svg-download, transparent, center, center, no-repeat, 100%, 100%, colors.$color-primary-1);
	}
}

.action-footer {
	color: var(--color-black);

	.not-touch & {
		&:hover {
			text-decoration: underline;
		}
	}
}

.action-contact a {
	width: 100%;
    font-size: 1.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    line-height: 1.3;
    max-width: 283px;
	border: 1px solid #404040;
	border-radius: 4px;
	padding: 8px 0;
	cursor: pointer;
	font-weight: 400!important;

	@include mq.mq($from: large) {
		width: 6.6rem;
		height: 2rem;
		padding: 0 0 2px;
		font-size: 1.4rem;
	}

	&:hover {
		background-color: #E6E6E6;
	}
}

.action-tag {
	background-color: var(--color-primary-1);
	color: var(--color-white);
	border-radius: 20px;
	padding: 0 20px 6px;
	font-weight: 600;
	@extend .font-size-lead;
	text-decoration: none;
	border: 1px solid transparent;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	

	&[data-active="false"] {
		background-color: transparent;
		color: var(--color-black);
		font-weight: 500;
		border: 1px solid transparent;

		@include mq.mq($from: large) {
			border: 1px solid var(--color-black);
		}

		&:hover {

			@include mq.mq($from: large) {
				border: 1px solid transparent;				
				background-color: var(--color-primary-1);
				color: var(--color-white);
			}
		}
	}

	&:not(:first-child) {
		margin-left: 2rem;
	}
	&:first-child, &:nth-child(2) {
		margin-left: 0;
	}
	
	@include mq.mq($from: large) {
		display: inline-block;
		margin-bottom: 1rem;
	}
}

.action-detail {
	color: var(--color-primary-1);
	display: block;
	margin-top: 1.5rem;
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: "";
}

.show-more {
	display: inline-block;
	margin-top: 8rem;
	font-size: var(--font-size-lead);
	color: var(--color-black);
	font-weight: 600;
	text-decoration: underline;
	line-height: 1.3;

	&:hover {
		text-decoration: none;
	}

	&__mt-3 {
		margin-top: 2.7rem;
	}

	&__mt-4 {
		margin-top: 4rem;
	}

	&--white {
		color: var(--color-white);
		
		@include mq.mq($from: large) {
			margin-left: 30rem;
		}
	}
}