@use "sass:color";
@use "../_config/colors";
@use "../mixins/mq";

video,
img {
	width: 100%;
	height: auto;
}

.figure {
	position: relative;
	overflow: hidden;
	height: 100%;

	&:after {
		position: absolute;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		content: '';
	}

	picture,
	img,
	video {
	/*	position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;*/
	}

	img {
		transition: transform var(--animationDuration) var(--animationEase);
		image-rendering: -webkit-optimize-contrast;
		backface-visibility: hidden;
	}

	&-three {
		height: 0;
		padding-bottom: 76%;
	}

	&-two {
		height: 0;
		padding-bottom: 72.25%;
	}

	&-fullscreen:after,
	&-cta:after {
		background-color: color.adjust(colors.$color-black, $alpha: -0.55);
	}
}

.figure-slider {
	height: 0;
	padding-bottom: 100%;

	@include mq.mq($from: medium) {
		padding-bottom: 77.56%;
	}
}

video::-webkit-media-controls,
video::video::-webkit-media-controls-enclosure,
video::-webkit-media-controls-overlay-play-button {
	display: none !important;
	-webkit-appearance: none !important;

}

.figure-headmodule {
	border-radius: 40px;
	overflow: hidden;

	img {
		object-fit: contain;
		aspect-ratio: 16 / 9;
	}
}