@use "../_config/colors";
@use "../_base/typography";
@use "../mixins/mq";

.c-teaser_entries__title {
    margin-bottom: 4rem;

    @include mq.mq($from: large){
        margin-bottom: 8rem;
    }
}

.c-teaser__grid {
    grid-row-gap: 6rem;
    margin-bottom: 1rem;

    @include mq.mq($from: medium) {

        grid-column-gap: 6rem;
    }

    @include mq.mq($from: large) {

        grid-row-gap: 4rem;
        grid-column-gap: 4rem;
    }
}

.c-teaser__4spalten {
    
    .card {
        max-width: 345px;

        @include mq.mq($from: large) {
            max-width: 394px;
        }
    }
}

.c-teaser__3spalten {
    .nocard {
        position: relative;

        &:hover {
            .action-detail {
                text-decoration: none;
            }
        }

        .preview-image {
            max-width: 100%;
        }
    }
}

.c-teaser__2spalten {
    margin-top: 15.5rem;
    --spacing-top-h2: 3.8rem;
    max-width: 108.8rem;
    display: flex;
    flex-direction: column;

    @include mq.mq($from: medium, $until: large) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 4rem;
    }

    .nocard {
        max-width: 345px;
        padding-bottom: 40px;
        transition: 0.1s ease-in-out;
        position: relative;
        height: 100%;
        
    
        @include mq.mq($from: large) {
            max-width: 394px;
            margin-bottom: 6rem;

            &:nth-child(even) {
                margin-left: auto;
            }
            &:not(:first-child) {
                margin-top: -27.7rem;
            }
        }

        &:hover {
            background-color: #ABD1B7;
            -webkit-box-shadow: 0px 5px 0px 30px #ABD1B7; 
            box-shadow: 0px 0px 0px 30px #ABD1B7;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }
    }
}