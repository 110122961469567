@use "../mixins/mq";

.text-meta {

    color: var(--color-black);

    .col {
        margin-bottom: 1.6rem;
    }

    &__list {
        display: flex;
        flex-direction: column;

        @include mq.mq($from: large) {
            flex-direction: row;

            .col:not(:first-child) {
                margin-left: 4rem;
            }
        }

        
    }

    &__title {
        font-weight: 500;
        margin-bottom: 8px;
        line-height: 1.3;
    }

    &__table-of-contents {
        list-style-type: none;
        font-size: 1.4rem;
        margin: 0;
        flex-direction: column;

        li {

            padding-left: 0;
            line-height: 1.3;
            margin-bottom: 8px;
            font-weight: 600;

            &::marker {
                content: none;
            }

            &:not(:first-child) {
                margin-top: 0;
            }

            .anchor {
                color: var(--color-black);
                font-weight: 600;
            }
        }
    }

    &__info {
        margin: 0;
        font-size: 1.4rem;
        font-weight: 600;
        white-space: nowrap;
    }

    &__link {

        color: var(--color-black);
    }

}

.text-meta {

    &__accordion--button {

        display: block;

        @include mq.mq($from: large) {
            display: none;
        }

    }
}


.accordion-title .icon-arrow_bottom {
    display: inline-block;

    @include mq.mq($from: large) {
        display: none;
    }

    svg {
        transition: 0.3s ease-in-out;
        transform-origin: center center;
     }
} 

 [data-accordion="close"] {

    .icon-arrow_bottom svg {
         transform: rotate(-90deg);
    }
 }

 .accordion-collapse {

    @include mq.mq($until: large) {
        display: none;
    }
    @include mq.mq($from: large) {
        display: flex!important;
    }
 }