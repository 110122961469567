@use "../mixins/mq";
@use "../mixins/col";
@use "../_base/typography";
@use "../atoms/action";
@use "../mixins/background-svg";
@use "../_base/svg";

.footer {
  color: var(--color-black);
  min-height: 446px;
  @include background-svg.params(svg.$svg-footer-bg-mobile, transparent, center, center, no-repeat, 100%, 100%, '');
  background-size: cover;
  
  @include mq.mq($from: medium, $until: large) {
    @include background-svg.params(svg.$svg-footer-bg-tablet, transparent, center, center, no-repeat, 100%, 100%, '');
    background-size: cover;
  }

  @include mq.mq($from: large) {
    min-height: 888px;
    @include background-svg.params(svg.$svg-footer-bg, transparent, center, center, no-repeat, 100%, 100%, '');
    background-size: cover;
  }
}

.footer__top {
  padding-top: 4rem;
  padding-bottom: 2rem;

  @include mq.mq($from: large) {
    padding-top: 21.2rem;
    padding-bottom: 5.4rem;
  }
}

.footer__top-left {
  padding-bottom: 1rem;
  font-size: 1.6rem;
  line-height: 1.3;

  @include mq.mq($from: large) {
    padding-bottom: 0;
    margin-left: 6.4rem;
    font-size: var(--font-size-h5);
  }
}

.footer__top-right {
  display: flex;
  flex-wrap: wrap;

  @include mq.mq($from: large) {
    margin-top: 2rem;
  }
}

.footer__address {
  line-height: 1.3;
  margin-top: 3.5rem;
  
  @include mq.mq($from: large) {
    margin-top: 7.5rem;
  }
}

.footer__address,
.footer__contact {
  @include mq.mq($from: large) {
    //width: calc(100% * 3 / 6 - #{6rem});
    @include col.params(4, 6, 6rem);
  }
}

.footer__contact {
  
}

.footer__bottom {
  padding-top: 0.3rem;
  padding-bottom: 2rem;
  font-size: 1.6rem;

  @include mq.mq($from: large) {
    padding-top: 2rem;
    font-size: 1.8rem;
  }

  .row {
    @include mq.mq($from: large) {
      align-items: center;
      justify-content: space-between;
    }
  }
}

.footer__bottom-left {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;

  @include mq.mq($from: large) {
    width: auto;
    margin-bottom: 0;
    margin-left: 6.4rem;
  }
}

.footer__home-link {
  display: inline-flex;
  margin-left: 0;

  > * {
    width: 295px;

    @include mq.mq($from: large) {
      width: 279px;
    }
  }

  .icon-logo {
    display: block;
  }
}

.footer__text {
  margin-top: 4rem;

  @include mq.mq($from: large) {
    margin-top: 7.9rem;
  }
}