@use "../mixins/mq";

.c-hero {

    .figure-header {

        margin-top: 4rem;
        border-radius: 8px;

        @include mq.mq($from: large) {
            max-width: 64.4rem;
        }
    }

    &__category {

        &:first-child {
            margin-top: 2rem;
            margin-bottom: 5rem;
            @include mq.mq($from: large) {
                margin-top: 4.7rem;
                margin-bottom: 10rem;
            }
        }
    }

    &__list {
        grid-column-gap: 0;

        @include mq.mq($from: small) {
            grid-column-gap: 4rem;
        }

        .card {
            grid-column-end: span 12;

            @include mq.mq($from: small) {
                grid-column-end: span 6;
            }  

            .preview-image {
                min-height: 19rem;
            }
        }
    }
}

