@use "../mixins/mq";

.filter__wrapper--magazin {

    .filter-items article:not(:first-child) {

        @include mq.mq($from: large) {
            margin-top: 0;
        }
    }

}
.card {

    background-color: var(--color-white);
    box-shadow: 0px 0px 0px 10px var(--color-white);
    border-radius: 10px;
    transition: 0.3s ease-in-out!important;
    height: 100%;//calc(100% - 6rem);
    padding-bottom: 0!important;
    position: relative;

    &:hover {
        background-color: #F4DE7C!important;
        box-shadow: 0px 0px 0px 10px #F4DE7C!important;
    }

    @include mq.mq($until: small) {
        width: 100vw;
        max-width: 345px;
        box-shadow: 0px 0px 0px 15px var(--color-white);

        &:hover {
            box-shadow: 0px 0px 0px 15px #F4DE7C!important;
        }
    }

    @include mq.mq($from: large) {
        margin-bottom: 0!important;
    }

    .preview-image{
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        height: 19rem;

        .figure picture {
            height: 100%;

            .img-filter {
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__wrapper {
        padding: 2.4rem;

        &--aktivsein {
            padding: 0;
        }
    }
    
    &__tag-wrapper {
        margin-bottom: 2.2rem;
        display: flex;
        justify-content: start;

        .no-tag & {
            display: none;
        }
    }

    .preview-text {
        margin-top: 2.4rem;
        line-height: 1.3;
    }

    .action-detail {
        margin-top: 0;
    }
}

.card-cta {
    padding: 0!important;
    width: 100%;

    &.card:hover {
        //background-color: var(--color-white)!important;
        //box-shadow: none!important;
    }

    @include mq.mq($from: small) {
        padding: 2rem 3rem!important;
    }
    @include mq.mq($from: large) {
        padding: 4rem 6rem!important;
    }

    .preview-text {
        margin-top: 4.1rem;
    }

    &__link {
        margin-top: 3.8rem;

        .action {
            width: 100%;
        }
    }
}

.card__agenda {
    border-radius: 20px;
    .card {

        &__title {
            margin-top: 2.3rem;
        }
        
        &__event-date {
            margin-top: 0;
        }

        &__place {
            margin-top: 2.3rem;
        }

        &__reg-until {
            margin-top: 0;
        }
    }
}

.card__aktivsein {
    max-width: 345px;
   
    @include mq.mq($from: medium) {
        max-width: 293px;   
    }
    

    @include mq.mq($from: large) {
        max-width: 394px;
        margin-bottom: 10.5rem;

        &:nth-child(even) {
            margin-left: auto;
        }
        &:not(:first-child) {
            margin-top: -27.7rem;
        }
    }
}