@use "../mixins/mq";

.component {

}


.c-edge + .c-edge,
.c-spaced + .c-edge {
	margin-top: 6rem;

	@include mq.mq($from: large) {
		margin-top: 12rem;
	}
}



.c-last {
	padding-bottom: 14rem;

	@include mq.mq($from: large) {
		padding-bottom: 16.5rem;
	}
}
