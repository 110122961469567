@use "../mixins/mq";

.heading {
    
    @include mq.mq($from: large) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 4.4rem;
    }

    .wrapper {
        --heading-wrapper: 1.6rem;

        padding-left: var(--heading-wrapper);
        padding-right: var(--heading-wrapper);

        @include mq.mq($from: large) {
            --heading-wrapper: 0;
        }
    }

    &__image {
        position: relative;
        min-height: 73.2rem;
        color: var(--color-white);
        padding: 4rem 0;
        display: flex;
        align-items: flex-end;

        @include mq.mq($from: large) {
            min-height: 99.8rem;
            width: 100%;
            padding: 6rem 0;
        }

        h1, .h1, .font-size-h1 {
            margin-bottom: 2.4rem;
        }

        h1:last-child, 
        .h1:last-child, 
        .font-size-h1:last-child {
            margin-bottom: 0;
        }
    }

    &__text {
        
        @include mq.mq($from: medium) {
            max-width: 68.8rem;
            margin: 0 auto;
        }

        @include mq.mq($from: large) {
            padding: 0;
            max-width: 52rem;
        }
    }

    &__events {
        margin-top: 4rem;

        @include mq.mq($from: medium) {
            max-width: 68.8rem;
            margin-left: auto;
            margin-right: auto;
        }

        @include mq.mq($from: large) {
            padding-right: 2rem;
            margin-left: 0;
            margin-right: 0;
        }

        .events__title {
            color: var(--color-black);
        }

        .events__list {
            display: grid;
            gap: 1.6rem;
            margin-top: 2.4rem;

            @include mq.mq($from: medium) {
                grid-template-columns: 1fr 1fr;
            }

            @include mq.mq($from: large) {
                gap: 2.4rem;
            }
        }
    }

    &__card {
        --card-shadow: var(--shadow);

        position: relative;
        background-color: var(--color-white);
        border-radius: 1rem;
        padding: 1.6rem;
        box-shadow: var(--card-shadow);
    }

    .action-show-all {
        margin-top: 1.6rem;
        font-size: 2.4rem;
        line-height: 1.3;
        color: var(--color-black);
        font-weight: 600;
        text-decoration: underline;

        @include mq.mq($from: large) {
            position: absolute;
            top: 0;
            right: 0;
        }

        &:hover {
            text-decoration: none;
        }
    }
}

.event {
    &__image {
        .figure-heading-events {
            border-radius: 1rem;

            @include mq.mq($from: large) {
                max-height: 16.4rem;
                aspect-ratio: 290 / 164;
            }
        }

    }

    &__title {
        font-size: 1.6rem;
        line-height: 1.3;

        @include mq.mq($from: large) {
            font-size: 1.8rem;
        }
    }

    &__meta {
        margin-top: 1.6rem;
        display: grid;
        gap: .8rem;

        > div, a {
            display: flex;
            gap: 1.6rem;
            font-size: 1.6rem;
            line-height: 1.3;

            .icon {
                min-width: 2rem;
            }

            @include mq.mq($from: large) {
                font-size: 1.8rem;
            }
        }
    }

    &__location {
        align-items: flex-start;

        .icon {
            margin-top: .2rem;

            @include mq.mq($from: large) {
                margin-top: .3rem;
            }
        }
    }

    &__calendar-save {
        color: var(--color-black);
        z-index: 10;
        align-items: flex-start;

        &:hover {
            text-decoration: none;
        }

        .icon {
            margin-top: .2rem;

            @include mq.mq($from: large) {
                margin-top: .3rem;
            }
        }
    }
}

.figure-heading {
    position: absolute;
    inset: 0;

    &:after {
        background: rgba(0, 0, 0, 0.4);
    }
    
    img {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.section--success {
    --card-shadow: none;

    .events__title, .action-show-all {
        color: var(--color-white);
    }
}