@use "../_base/svg";
@use "../_config/colors";
@use "../_base/typography";
@use "../utils/visually-hidden";
@use "../mixins/background-svg";
@use "../mixins/mq";
@use "../mixins/col";

.field__row {

	* {
		font-family: inherit
	}

}

.field-group {
	position: relative;
	margin-top: 5rem;

	&--half {
		width: 100%;

		@include mq.mq($from: large) {
			width: calc(50% - #{3rem});
		}
	}

	&--third {
		width: 100%;

		@include mq.mq($from: large) {
			width: calc(33% - #{3rem});
		}
	}

	&--large {
		width: 100%;
	}

	&--medium {
		width: 100%;

		@include mq.mq($from: large) {
			@include col.params(4, 6, 6rem);
		}
	}

	&--small {
		width: 100%;

		@include mq.mq($from: large) {
			@include col.params(2, 6, 6rem);
		}
	}

	.row & {
		& + .field-group {

			@include mq.mq($from: large) {
				margin-left: 6rem;
			}
		}
	}

	&--bordered {
		border: 1px solid var(--color-black);

		&.field-group--has-errors {
			border: 2px solid var(--color-error);

			
		}
	}

	&--checkbox {
		margin-top: 6rem;
	}

	&--html {
		margin-top: var(--spacingWide);
		color: var(--color-black);

		@include mq.mq($from: large) {
			margin-top: 10rem;
		}
	}

	&--block {
		margin-top: 6.7rem;
	}
}


.field__input {
	position: relative;
	display: block;
	min-height: 3.7rem;
	max-width: 100%;
	border: 0;
	width: 100%;
	background: var(--color-white);
	font-size: var(--font-size-h5);
	padding: 7px 18px;

	&--file {
		width: auto;
		min-height: unset;
	}
}

.field__label {
	position: relative;
	display: block;

	&--mb {
		margin-bottom: 3.9rem;
	}

	.field-group--block &, .field-group--select &, .field-group--file_drag_and_drop & {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		transform: translateY(-2.7rem);
		font-size: 1.4rem;
		letter-spacing: -0.3px;
		//transition: all var(--animationDuration) var(--animationEase);
		cursor: pointer;
	}

	.field-group--block-animated & {
		/*font-size: 1.7rem;
		line-height: 2rem;
		color: var(--color-grey-200);
		@extend .font-weight-400;
		transform: translateY(-2.7rem);
		*/
	}

	.field-group--textarea.field-group--block-animated & {
		//transform: translateY(-3.5rem);
	}



	&.required {
		&:after {
			content: '*';
		}
	}
}


input[type=checkbox],
.field__input--check,
.field__input--radio {
	@extend .visually-hidden;
}


.field__sub-label {
	position: relative;
	display: block;
	padding-top: 0.2rem;
	margin-top: 0;
	@extend .font-size-h5;
	padding-left: 2.5rem;
	min-height: 3.9rem;
	cursor: pointer;

	&:before {
		position: absolute;
		left: 0;
		top: 0.8rem;
		display: block;
		width: 1.6rem;
		height: 1.6rem;
		content: '';
		border: 1px solid var(--color-black);
		background: var(--color-white);
	}

	.field__input--radio + & {
		&:before {
			border-radius: 50%;
		}
	}

	input[type=checkbox]:checked + &,
	.field__input--check:checked + & {
		&:before {
			background: var(--color-black);
		}
	}

	.field__input--radio:checked + & {
		&:after {
			position: absolute;
			left: 0;
			top: 0.8rem;
			display: block;
			content: '';
			width: 1.8rem;
			height: 1.8rem;
			background-color: var(--color-black);
			border-radius: 50%;
		}
	}

	&--inline {
		display: inline-flex;
		margin-right: 4rem;
	}
}

.field__input--textarea {
	transition: height var(--animationDuration) var(--animationEase);
}

.ff-form-errors,
.ff-form-success {
	margin-bottom: 6rem;
	@extend .font-size-h3;
	@extend .font-weight-600;
}

.ff-form-success {
	color: var(--color-success);
}

.ff-form-errors {
	color: var(--color-error);
}


.ff-errors {
	position: absolute;
	top: 100%;
	font-size: 1.6rem;
	line-height: 3.2rem;
	letter-spacing: 0.1rem;
	@extend .font-size-h6;
	@extend .font-weight-500;
	color: var(--color-primary-1);
	margin-top: 0.2rem;
	margin-left: 0;
	letter-spacing: -0.1rem;

	:is(.field-group--checkbox_group) + & {
		position: relative;
		top: auto;
		margin-top: 1rem;
	}

	li {
		&::marker {
			content: none;
		}
		padding: 0;
	}
}

.field-group--submit {
	.action + .action {
		margin-left: 2rem;
	}
}
